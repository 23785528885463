import { Injectable } from '@angular/core';
import { ExperimentsDto } from '../../../generated-code/logic-core-api';
import { ExperimentsRow } from '../../core/models/experiments-row.model';

@Injectable({
  providedIn: 'root',
})
export class ExperimentsHelperService {
  createRows(experiments: ExperimentsDto) {
    const rows: ExperimentsRow[] = [];
    for (const row of experiments.rows || []) {
      const expRowId = row?.id || '';
      const newRow: ExperimentsRow = { _expRowId: expRowId, notes: row.notes };
      for (const col of experiments.columns || []) {
        const expColId = col?.id || '';
        const value = experiments?.values?.find((v) => v.expColId === expColId && v?.expRowId === expRowId);
        if (value) {
          newRow[expColId] = value.val;
        }
      }
      rows.push(newRow);
    }
    return rows;
  }
}
