import { Widget } from '../../core/models/widget.model';

export class ResetVisualizationsState {
  static readonly type = '[VISUALIZATIONS] Reset';
}

export class LoadWidgets {
  static readonly type = '[VISUALIZATIONS] Load Widgets';
}

export class AddWidget {
  static readonly type = '[VISUALIZATIONS] Add Widget';
  constructor(
    public widget: Widget, //FIXME: Type
  ) {}
}

export class RemoveWidget {
  static readonly type = '[VISUALIZATIONS] Remove Widget';
  constructor(
    public widget: Widget, //FIXME: Type
  ) {}
}

export class SelectWidget {
  static readonly type = '[VISUALIZATIONS] Select Widget';
  constructor(
    public widget: Widget | null, //FIXME: Type
  ) {}
}

export class UpdateWidget {
  static readonly type = '[VISUALIZATIONS] Update Widget';
  constructor(
    public widget: any, //FIXME: Type
  ) {}
}

export class SetGridColumns {
  static readonly type = '[VISUALIZATIONS] Set Grid Columns';
  constructor(public gridColumns: 1 | 2 | 3) {}
}
