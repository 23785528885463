import { inject, Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { catchError, switchMap, tap } from 'rxjs';
import {
  Doe,
  DoeCreateDto,
  DoeInfoDto,
  DoeService,
  DoeStateValues,
  LaunchDoeRequestDto,
  RecomendationsService,
} from '../../../generated-code/logic-core-api';
import { ConfigurationSelectors } from '../configuration/configuration.selectors';
import { RenameProjectTab } from '../project/project.actions';
import { ProjectSelectors } from '../project/project.selectors';
import {
  CloneDoe,
  CreateDoe,
  CreateRecomendation,
  DeleteDoe,
  LoadDoeById,
  LoadDoesByProjectId,
  LoadStatuses,
  SaveDoeChanges,
  UnloadDoeById,
  UpdateDoe,
} from './does.actions';

const STATE_NAME = 'does';
export class DoesStateModel {
  loadingDoes!: boolean;
  loadingDoeInfo!: boolean;
  loadingStatuses!: boolean;
  error: any | null;
  does!: Doe[];
  doeById!: { [key: string]: DoeInfoDto | null };
  newDoe!: Doe | null;
  doeStatuses!: { [key: string]: any };
}

@State<DoesStateModel>({
  name: STATE_NAME,
  defaults: {
    loadingDoes: false,
    loadingDoeInfo: false,
    loadingStatuses: false,
    error: null,
    does: [],
    doeById: {},
    newDoe: null,
    doeStatuses: {},
  },
})
@Injectable()
export class DoesState {
  store = inject(Store);
  doesAPIService = inject(DoeService);
  recomendationsAPIService = inject(RecomendationsService);

  @Action(LoadDoesByProjectId)
  loadDoes({ patchState }: StateContext<DoesStateModel>, { projectId }: LoadDoesByProjectId) {
    patchState({
      loadingDoes: true,
    });
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id || '';
    const filters = { tenant: tenantId };
    return this.doesAPIService.apiV1DoesProjectIdGet(projectId, undefined, undefined, filters).pipe(
      tap(({ items }) => {
        patchState({
          loadingDoes: false,
          does: items || [],
        });
      }),
      catchError((error) => {
        patchState({
          loadingDoes: false,
          does: [],
          error,
        });
        return error;
      }),
    );
  }

  @Action(LoadDoeById)
  loadDoe({ patchState, getState, setState }: StateContext<DoesStateModel>, { projectId, doeId }: LoadDoeById) {
    patchState({
      loadingDoeInfo: true,
    });
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.doesAPIService.apiV1DoesProjectIdDoeIdGet(projectId, doeId, tenantId).pipe(
      tap((doe) => {
        const state = getState();
        const doeById = { ...state.doeById, [doeId]: doe };
        patchState({
          doeById,
          loadingDoeInfo: false,
        });
      }),
      catchError((error) => {
        const state = getState();
        const doeById = { ...state.doeById, [doeId]: null };
        patchState({
          doeById,
          loadingDoeInfo: false,
        });
        throw error;
      }),
    );
  }

  @Action(UnloadDoeById)
  unloadDoe({ patchState, getState }: StateContext<DoesStateModel>, { doeId }: UnloadDoeById) {
    const state = getState();
    const doeById = { ...state.doeById };
    delete doeById[doeId];
    patchState({
      doeById,
    });
  }

  @Action(SaveDoeChanges)
  saveChanges({ patchState, getState }: StateContext<DoesStateModel>, { doe }: SaveDoeChanges) {
    const state = getState();
    const doeId = doe?.doe?.id as string;
    const doeById = { ...state.doeById, [doeId]: doe };
    patchState({
      doeById,
    });
  }

  @Action(CreateDoe)
  createDoe({ dispatch }: StateContext<DoesStateModel>, { projectId, newDoe }: CreateDoe) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.doesAPIService.apiV1DoesProjectIdPost(projectId, tenantId, newDoe).pipe(
      switchMap(() => {
        const selectedProject = this.store.selectSnapshot(ProjectSelectors.selectedProject);
        return dispatch(new LoadDoesByProjectId(selectedProject?.id || ''));
      }),
    );
  }

  @Action(DeleteDoe)
  deleteDoe({ dispatch }: StateContext<DoesStateModel>, { projectId, doe }: DeleteDoe) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.doesAPIService.apiV1DoesProjectIdDoeIdDelete(projectId, doe.id || '', tenantId).pipe(
      switchMap(() => {
        const selectedProject = this.store.selectSnapshot(ProjectSelectors.selectedProject);
        return dispatch(new LoadDoesByProjectId(selectedProject?.id || ''));
      }),
    );
  }

  @Action(UpdateDoe)
  updateDoe({ dispatch, patchState, getState }: StateContext<DoesStateModel>, { projectId, doe }: UpdateDoe) {
    const updatedDoe: DoeCreateDto = {
      projectId,
      name: doe?.doe?.name || '',
      state: (doe?.doe?.state as DoeStateValues) || DoeStateValues.Draft,
      notes: doe?.doe?.notes || '',
      factors: doe.factors || [],
      responses: doe.responses || [],
      constraints: doe.constraints || [],
      filters: doe.filters || [],
    };

    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.doesAPIService.apiV1DoesProjectIdDoeIdPut(projectId, doe.doe?.id || '', tenantId, updatedDoe).pipe(
      switchMap(() => {
        const selectedProject = this.store.selectSnapshot(ProjectSelectors.selectedProject);
        patchState({
          doeById: { ...getState().doeById, [doe.doe?.id as string]: doe },
        });
        return dispatch(new RenameProjectTab(doe?.doe?.id as string, doe?.doe?.name as string)).pipe(
          switchMap(() => dispatch(new LoadDoesByProjectId(selectedProject?.id || ''))),
        );
      }),
    );
  }

  @Action(CloneDoe)
  cloneDoe({ dispatch, patchState }: StateContext<DoesStateModel>, { projectId, doe }: CloneDoe) {
    const newDoe: DoeCreateDto = {
      ...doe.doe,
      projectId,
      name: `Copy of ${doe?.doe?.name}`,
      state: DoeStateValues.Draft,
      factors: doe.factors,
      responses: doe.responses,
      constraints: doe.constraints,
      filters: doe.filters,
    };
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.doesAPIService.apiV1DoesProjectIdPost(projectId, tenantId, newDoe).pipe(
      tap((doe) => patchState({ newDoe: doe })),
      switchMap(() => {
        const selectedProject = this.store.selectSnapshot(ProjectSelectors.selectedProject);
        return dispatch(new LoadDoesByProjectId(selectedProject?.id || ''));
      }),
    );
  }

  @Action(LoadStatuses)
  loadStatuses({ getState, patchState }: StateContext<DoesStateModel>, { projectId, doeId }: LoadStatuses) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    patchState({
      loadingStatuses: true,
    });
    return this.recomendationsAPIService.apiV1RecomendationsProjectIdDoeIdGet(projectId, doeId, tenantId).pipe(
      tap((statuses: any) => {
        const state = getState();
        const doeStatuses = { ...state.doeStatuses, [doeId]: statuses };
        patchState({
          doeStatuses,
          loadingStatuses: false,
        });
      }),
      catchError((error) => {
        const state = getState();
        const doeStatuses = { ...state.doeStatuses, [doeId]: null };
        patchState({
          doeStatuses,
          loadingStatuses: false,
        });
        return error;
      }),
    );
  }

  @Action(CreateRecomendation)
  runDoe({ dispatch }: StateContext<DoesStateModel>, { projectId, doeId, nRecomentations }: CreateRecomendation) {
    const tenant = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    const launchDoeRequest: LaunchDoeRequestDto = {
      jobName: 'jobName',
      numRecs: nRecomentations,
      funcAdquisition: 'GM',
    };
    return this.recomendationsAPIService
      .apiV1RecomendationsProjectIdDoeIdPost(projectId, doeId, tenant, launchDoeRequest)
      .pipe(
        tap(() => {
          dispatch(new LoadStatuses(projectId, doeId));
        }),
      );
  }
}
