import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Widget } from '../../core/models/widget.model';
import {
  AddWidget,
  LoadWidgets,
  RemoveWidget,
  ResetVisualizationsState,
  SelectWidget,
  SetGridColumns,
  UpdateWidget,
} from './visualizations.actions';

const STATE_NAME = 'visualizations';
export class VisualizationsStateModel {
  loading!: boolean;
  error!: any | null;
  widgets!: Widget[]; //FIXME: Type
  selectedWidget!: Widget | null; //FIXME: Type
  gridColumns!: 1 | 2 | 3;
}
@State<VisualizationsStateModel>({
  name: STATE_NAME,
  defaults: {
    loading: false,
    error: null,
    widgets: [],
    selectedWidget: null,
    gridColumns: 2,
  },
})
@Injectable()
export class VisualizationsState {
  @Action(ResetVisualizationsState)
  resetProjectsState({ setState }: StateContext<VisualizationsStateModel>) {
    setState({
      loading: false,
      error: null,
      widgets: [],
      selectedWidget: null,
      gridColumns: 2,
    });
  }

  @Action(LoadWidgets)
  loadVisualizations({ patchState }: StateContext<VisualizationsStateModel>) {
    patchState({
      loading: true,
    });
    return setTimeout(() => {
      patchState({
        widgets: [],
        loading: false,
      });
    }, 1000);
  }

  @Action(AddWidget)
  addWidget({ patchState, getState }: StateContext<VisualizationsStateModel>, { widget }: AddWidget) {
    patchState({
      widgets: [...getState().widgets, widget],
    });
  }

  @Action(RemoveWidget)
  removeWidget({ patchState, getState }: StateContext<VisualizationsStateModel>, { widget }: RemoveWidget) {
    patchState({
      widgets: getState().widgets.filter((w) => w.id !== widget.id),
    });
  }

  @Action(SelectWidget)
  selectWidget({ patchState }: StateContext<VisualizationsStateModel>, { widget }: SelectWidget) {
    patchState({
      selectedWidget: widget,
    });
  }

  @Action(UpdateWidget)
  updateWidget({ patchState, getState }: StateContext<VisualizationsStateModel>, { widget }: UpdateWidget) {
    const index = getState().widgets.findIndex((w) => w.id === widget.id);
    const widgets = [...getState().widgets];
    widgets[index] = widget;
    patchState({
      widgets,
    });
  }

  @Action(SetGridColumns)
  setGridColumns({ patchState, getState }: StateContext<VisualizationsStateModel>, { gridColumns }: SetGridColumns) {
    patchState({
      gridColumns,
    });
  }
}
